import React from 'react';
import { Helmet } from 'react-helmet'

const RomaniaModerna = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 pt-20 bg-white text-[#002A59] font-eleron min-h-screen bg-opacity-90">
      <Helmet>
        <title>Despre USR </title>
      </Helmet>
      <h1 className="text-3xl font-bold text-red-600 text-center mb-6">România Modernă. Reforma cinstită</h1>
      <h2 className="text-2xl font-extrabold mb-4 text-white bg-[#002A59] pl-7 pr-7 pb-2">Doctrina de Centru Dreapta Modern</h2>

      <h3 className="text-2xl font-semibold mb-1">I. De ce centru dreapta modern? Context doctrinar</h3>
      <p className="mb-1 text-l p-2  indent-3 text-justify underline italic">
      USR este partidul nou, anti-sistem, care coagulează energiile celor care vor cu adevărat o schimbare, care se implică în viața publică.
      </p>
      <p className=' mb-1 text-l p-2  indent-3 text-justify'>La 30 de ani de la Revoluție, România este în impas. Pentru că noi, cetățenii, am lăsat responsabilitatea schimbării pe seama statului, pe seama partidelor vechi, pe seama birocrației și pe seama celor care au de câștigat din starea lucrurilor. Pe seama celorlalți. Pentru a schimba starea de lucruri, trebuie să mobilizăm energiile în jurul valorilor schimbării. Pe care le generăm noi și comunitățile în care trăim, muncim, ne educăm copiii.</p>
      <p className=' mb-1 text-l p-2  indent-3 text-justify'>La trei ani de la intrarea în Parlament și mai puțin de un an înainte de alegerile parlamentare, trebuie să spunem clar cetățenilor cum vrem să schimbăm România. Vremea oscilațiilor a trecut. Câtă vreme am fost contra a ceva, a fost ușor. Acum e vremea să spunem PENTRU ce suntem. Clarificarea a început în practică, asumarea a fost împiedicată de un proces de decizie neclar. Azi este momentul să încheiem acest proces.</p>
      <p className=' mb-1 text-l p-2  indent-3 text-justify'> <span className='font-bold'>Valorile în care credem sunt</span>: Libertatea, însoțită de responsabilitate. Economie de piață și libertatea economică. Educație și șanse pentru toți. Respectul și garantarea proprietății private. Libertate religioasă. Respectul față de viața privată. Demnitatea persoanei. Dreptatea, Adevă- rul. Competența. Meritocrația. Integritatea. Respectul față de lege și stat de drept. Libertatea de expresie. Drepturile omului. Respectul față de cultura europeană clasică. Valorile și principiile morale euro-atlantice.</p>
      <p className='mb-1 text-l p-2 indent-3 text-justify'> <span className='font-bold'>De ce centru-dreapta?</span> Pentru că dreapta este în România forța schimbării, forța care a dus România înainte spre economie de piață, spre Europa, spre libertate. Pentru că stânga a împins România, și nu doar de 30 de ani, la marginea Europei.</p>
      <p className="mb-1 italic underline">
        Doctrina ”USR centru-dreapta modern” este o sinteză între liberalismul european și puterea comunităților.
      </p>
      <p className='mb-1 text-l p-2 m- indent-3 text-justify'><span className='font-bold'>Luăm din liberalism</span> atașamentul față de libertate, proprietate, antreprenoriat, competiție des- chisă, egalitate în fața legii, dreptul de a căuta fericirea așa cum o definește fiecare individ.</p>
      <p className='mb-1 text-l p-2 m- indent-3 text-justify'><span className='font-bold'>Luăm din comunitarianism</span> conștiința faptului că trăim într-o societate care ne-a croit șansele, ideile și așteptările, și e misiunea noastră comună să facem cât mai mult bine cât mai multor indivizi liberi.</p>
      <p className='mb-1 text-l p-2 m- indent-3 text-justify'>USR susține minimizarea intervenției statului în viața oamenilor, libertatea de a alege și împu- ternicirea comunităților prin descentralizare și aplicarea efectivă a principiului subsidiarității. Intervenția publică trebuie realizată prin intermediul comunității (prin comunitate înțelegând fundații, asociații, instituții de cult, autorități locale).</p>
      <p className='mb-1 text-l p-2 m- indent-3 text-justify'><span className='font-bold'>Împărtășim cu toate curentele de centru dreapta (creștin democrație, conservatorism, ordo- liberalism)</span> grija față de semeni și adaptăm răspunsurile lor la lipsurile individualismului clasic. Respingem ferm intervenționismul de dragul intervenției și redistribuirea de dragul redistribuirii. Considerăm că intervenția statului trebuie să fie limitată în timp și mijloace și mereu supusă verificării.</p>
      <p className='mb-1 text-l p-2 m- indent-3 text-justify'>Protejăm libertatea religioasă, pe care o vedem ca un drept fundamental pentru toți cetățenii care trăiesc pe teritoriul României. Recunoaștem faptul că a trăi într-o credință religioasă este o valoare fundamentală, pe care romanii trebuie sa continue să o poată exercita în deplină libertate.</p>
      <p className='mb-1 text-l p-2 m- indent-3 text-justify'>Apartenența la Uniunea Europeană și la NATO sunt realizări importante ale României, pe care trebuie să le consolidăm. Uniunea Europeană este un factor de modernizare insuficient folosit și este momentul să gândim dezvoltarea strategică a României în linie cu oportunitățile oferite de apartenența noastră la acest club select, cât și cu tendințele globale unde UE este campioană. Investițiile în apărare sunt esențiale într-o lume tot mai volatilă, România în tandem cu partenerii noștri de securitate trebuie să fie un punct de stabilitate în regiune.</p>
      <h3 className="text-2xl font-semibold mb-1">II. 5 principii de guvernare. Liberalism + forța comunităților în practica guvernării</h3>
      <p className="mb-1 text-l p-2 m- indent-3 text-justify">La 30 de ani de la Revoluție, într-o nouă societate și într-o nouă economie, doctrinele politice trebuie aduse la zi. <strong>Sinteza noastră doctrinară se adresează tuturor cetățenilor români</strong>. Celor care au resurse să își creioneze propria fericire – care vor doar ca statul să nu le mai încurce viețile, să se ocupe de infrastructura de bază (fizică, digitală, educațională) fără să le mai strice viața. Celor care vor o țară ca afară pentru că au văzut în alte părți ce ne lipsește aici. Dar tre- buie să se adreseze și celor care nu au resurse, celor pe care statul, partidele și instituțiile i-au trădat. Celor de care avem datoria patriotică de a avea grijă – pentru că patriotismul nostru este patriotismul grijii față de acea Românie pe care trebuie să o ajutăm să iasă din sărăcie și să intre în modernitate.
      </p>
      <h3 className="text-2xl font-semibold mb-1">Propunerile de politici pe care le vom dezvolta în continuare</h3>
      <p className='text-l text-justify'>pleacă de la câteva principii de bază:</p>
      <ol>
        
        <li className=' mb-1 text-l p-2 m- indent-3 text-justify'>1.<strong>Libertatea individului este fundamentală.</strong> Individul poate fi liber, în căutarea propriei feri- ciri, atâta timp cât are posibilitatea și responsabilitatea propriei evoluții. Libertatea individuală este inexorabil legată de nivelul de respect acordat proprietății Românii știu cel mai bine ce își doresc pentru a fi fericiți, noi ne vom asigura că statul nu le va mai pune piedici în calea lor spre împlinire.</li>
        <li className=' mb-1 text-l p-2 m- indent-3 text-justify'>2.<strong>Egalitatea în fața legii și a statului și eliminarea privilegiilor discriminatorii.</strong> Româniii se împart de facto în două categori: cei care au privilegii făcute prin legi nedrepte, prin birocrație interesată, prin reguli absurde (de la baroni, moguli până la niveluri mai mici) și cei care sunt în afara USR este vocea cetățenilor care vor egalitate în drepturi, în șanse, în fața legii. USR vrea ca statul român să îi trateze egal pe toți atunci când vine vorba despre servicii publice. Fiecare persoană sau organizație plătită din bani publici tre- buie să aibă un rol social, să furnizeze un bun sau un serviciu public. Vom înceta să plătim organizații și instituții doar pentru că există.</li>
        <li className=' mb-1 text-l p-2 m- indent-3 text-justify'>3.<strong>Modernizare liberală cu puterea comunităților</strong> suntem forțele anti-status-quo actual reprezentative pentru această generație. Pro-europenismul nostru este instinctiv: vrem o țară ca afară! S-au mai încercat salturi de modernizare în istoria României. Defectul con- genital al României a fost mereu un rol prea mare pentru stat și o ignorare sau chiar des- considerarea și abuzul de putere asupra cetățenilor și a comunităților. Statul a eșuat, mo- dernizarea României seamănă cu o misiune sisifică și fiecare generație pare că trebuie să o ia de la capăt. E timpul să încercăm USR a pornit ca voce a cetățenilor care s-au să- turat să stea deoparte. USR aduce în spațiul politic vocea unei generații care își dorește să încheie modernizarea țării, pe model european. România va fi o țară modernă când indivizii vor fi liberi să își atingă potențialul și când comunitățile vor fi mature și vor avea puterea de a se gestiona. Acesta este mandatul neo-pașoptist al generației noastre.</li>
        <li className=' mb-1 text-l p-2 m- indent-3 text-justify'>4.<strong>Statul este o organizație care trebuie permanent ținută sub control, analizată și adap- tată la standardele moderne.</strong>  Statul român este o birocrație scăpată de sub control din cauză că a) implicarea civică e scăzută, b) partidele vechi sunt găști de control a unor bucăți de stat și c) birocrații își inventează funcții și USR este răspunsul la toate aceste trei probleme: implicăm civic-politic cetățenii, raționalizăm statul, reluăm contro- lul democratic-cetățenesc asupra birocrației. Ineficiența administrativă și iresponsabili- tatea coexistă în instituțiile publice cu lipsa de resurse și cu oameni care își fac onest și cu dedicație datoria. Efortul lor se pierde într-un sistem prost condus. Cei onești și muncitori nu sunt ajutați de sistem, nu sunt recunoscuți și recompensați. Misiunea noastră este să punem în valoare talentele care servesc acum interesul public și să atragem altele, într-un sistem adaptat la modernitate.</li>
        <li className=' mb-1 text-l p-2 m- indent-3 text-justify'>5.<strong>Meritocrația și competiția onestă trebuie să devină norme sociale și politice în România.</strong> USR a fost primul partid parlamentar care a anunțat concursuri publice deschise pentru pozițiile de demnitate publică unde partidul a fost chemat să desemneze numirile în stat, la orice nivel, trebuie să caute în mod real talente și aptitudini din societate, a lucra pentru binele public trebuie să redevină o chemare pentru cei mai buni dintre noi. Aceasta este profesiunea noastră de credință.</li>
      </ol>
     <h3 className="text-2xl font-semibold mb-1">III. Planul de acțiune. Ce vom face în viitorul imediat?</h3>
     <h4 className='text-l font-semibold mb-1'>Pasul 1</h4>
     <p className=' mb-1 text-l p-2 m- indent-3 text-justify'>Membrii USR își asumă ”centru dreapta modern” ca doctrina USR. Este finalul unui proces refle- xiv și deliberativ care s-a derulat în partid și în jurul partidului, cristalizat prin aceste principii și idei de guvernare. Un partid care a adunat forțele noi, reformiste încheie democratic procesul de autodefinire.</p>
     <h4 className='text-l font-semibold mb-1'>Pasul 2</h4>
     <p className=' mb-1 text-l p-2 m- indent-3 text-justify'>Vom face opoziție real liberală la guvernul Partidului Național Liberal. Nu vom intra în competiția populistă în care clasa politică (PSD, PNL, PDL, USL etc.) a aruncat România în ultimele decenii, potrivit căreia opoziția cere să ”se dea” de la stat și guvernul ba dă, ba zice că nu sunt bani, dar și unii și alții aruncă în aer bugetele și provoacă crize economice cu efecte sociale grave. Vom propune soluții realiste, moderate și moderne.</p>
     <h4 className='text-l font-semibold mb-1'>Pasul 3</h4>
     <p className=' mb-1 text-l p-2 m- indent-3 text-justify'>Vom participa la alegerile locale și parlamentare de pe o platformă bazată pe liberalism european și acțiuni ale comunităților. Vom negocia cu partenerii de guvernare (niciodată PSD) un program de guvernare bazat pe propunerile din acest document detaliat, pe un calendar precis de implementare și cu pași prestabiliți.</p>
     <h4 className='text-l font-semibold mb-1'>Pasul 4</h4>
     <p className=' mb-1 text-l p-2 m- indent-3 text-justify'>Vom imprima o turnură liberală modernă agendei de guvernare și vom fi partidul de centru dreapta modern care sparge cercul vicios al populismului, al traseismului politic, al capturii statului și al iresponsabilității. Vom fi la guvernare imediat ce românii ne vor acorda încredere și vom găsi parteneri pentru aceste idei. Oricum ar fi, vom fi aici, implicați și organizați forța care va schimba România.</p>
     <p className='font-bold underline'>Ne vom împlini astfel destinul. Vom putea spune copiilor noștri: am fost acolo.</p>
     <h3 className="text-2xl font-semibold m-3">IV. Cum vom schimba România cu o abordare de centru dreapta modern?</h3>
     <h4 className='text-l font-semibold mb-1'>10 pachete de politici pe care se va baza guvernarea USR:</h4>
     <ol>
      <li className='mt-4'><strong>1. Libertatea antreprenorială</strong><p className=' mb-1 text-l p-2  indent-3 text-justify'> Susținem libertatea economică deplină în condițiile unui intervenționism restrâns al statului în economie. România a ajuns să fie o economie bazată pe afacerile cu statul, o economie care constrânge capacitatea antreprenorială prin birocrație excesivă și reguli care se schimbă pes- te noapte. Propunem un parteneriat real cu mediul de afaceri. Fără surprize. Credem în liberă inițiativă și în capacitatea societății românești de a se dezvolta corect și prosper în condiții de piață liberă.</p></li>
      <li className='mt-4'><strong>2. Cumpătare, responsabilitate economică și reducerea impozitării muncii</strong> <p className=' mb-1 text-l p-2  indent-3 text-justify'>Portretul economic al României este unul sumbru și paradoxal. Pe de o parte, România se con- fruntă cu o lipsă majoră de forță de muncă. Pe de altă parte, românii pleacă să muncească în alte țări în număr record. Iar, în aceleași timp, foarte mulți dintre cei care rămân în România nu muncesc în economia formală, România având a 3-a cea mai mare rată de inactivitate din Uniunea Europeană. Toate acestea nu sunt întâmplătoare. Tot în România avem cele mai mari taxe pe salariul minim din toată Europa. Lucrul acesta trebuie să înceteze. Vom reduce gradual impozitarea muncii astfel încât salariul minim să nu mai fie taxat și toate taxele să fie calculate de la nivelul salariului minim în sus. Impactul bugetar va fi suportat de eliminarea programelor inutile de tip PNDL, a pensiilor speciale și prin stabilizarea cheltuielilor publice (care au crescut cu 46 de miliarde de lei în 2018 și 50 de miliarde de lei în 2019). Mai multe detalii pe <a className='font-bold text-blue-600' href="www.zerotaxe.ro">www.zerotaxe.ro</a>. Vom apăra <strong>cota unică</strong> - nu impozităm progresiv pe cei care muncesc mai mult. Predictibilitatea economică este vitală oricărui sistem care are nevoie să se dezvolte. Susținem bugetare multi-anuală reală. Milităm pentru o <strong> trecere rapidă la moneda comună euro. Deficit bugetar controlat, inflație redusă, creditare facilă</strong> - clasa de mijloc are nevoie de predictibilitate, vom fi partidul care spune NU populismului, NU pomenilor electorale, NU creșterii datoriei pe care o vor plăti copiii noștri.</p></li>
      <li className='mt-4'><strong>3. Pensii egale la contribuții egale. Fără pensii speciale.</strong>
      <p className=' mb-1 text-l p-2  indent-3 text-justify'>Desființăm pensiile speciale. Toți trebuie să fim egali în fața statului. Nu e niciun motiv pentru care unii ar trebui să fie “mai speciali” și să primească pensii peste cât au contribuit. Fiecare pensie în funcție de contribuție. Încetăm aberația raportării anumitor pensii la ultimul salariu, care de obicei este umflat cu tot felul de sporuri și care a creat cele mai mari abuzuri din sistem.</p>
      <p className=' mb-1 text-l p-2  indent-3 text-justify'>Recunoaștem faptul că sistemul actual de pensii este complet nesustenabil. Explozia punctului de pensie nu face decât să-i scurteze și mai mult durata de viață. Dacă vom continua fără schim- bări, vom pune pe umerii copiilor noștri o povară uriașă. Vom avea grijă de pensiile părinților noștri. Vom accelera tranziția de la pilonul 1, falimentar și care se bazează pe transferuri de la buget, către pilonul 2 și pilonul 3, bazate pe investiții. Tranziția se va face fără a afecta pensi- ile actuale, dar cu un calendar clar de transfer pentru viitorii pensionari. Vom folosi veniturile din resursele naturale ale țării noastre pentru a ajuta această tranziție: resursele naturale ale României merg direct ca investiții în pensiile din pilonul 2 (<a className='font-bold text-blue-600' href="https://usr.ro/wp-content/uploads/2019/08/Policy-Brief-Marea-Neagra-19.08.2019.pdf">detalii despre politica USR de legare a resurselor naturale de viitorul sistemului de pensii în această propunere</a>)</p>
      <p className=' mb-1 text-l p-2  indent-3 text-justify'>Vom avea grijă de pensiile părinților noștri și nu ne vom condamna copiii.</p>
      </li>
      <li className='mt-4'><strong>4. Politici anti-sărăcie concrete</strong>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Empatie față de semenii noștri aflați în sărăcie sau la limita sărăciei. Nu putem construi România modernă lăsând români în urmă. Susținem programe clare și măsurabile de scoatere din sărăcie a concetățenilor noștri, prin măsuri de activare și educare. Susținem declanșarea unei ofensive decise împotriva unui flagel: imensul abandon școlar în mediile sărace și în spațiul rural. Statul trebuie să intervină nu cu pomeni sociale, ci cu măsuri moderne de activare socială. Intervențiile sociale trebuie finanțate prin granturi oferite <strong> organizațiilor care lucrează direct în comunități</strong>: ONG-uri, fundații, instituții de cult, primării. Mai puțină birocrație de stat în politicile anti-sărăcie, mai multă intervenție comunitară. Nu mai impozităm sărăcia: zero taxe pe salariul minim (primii 2.230 lei din salariu).</p>
      </li>
      <li className='mt-4'><strong>5. Puterea în mâna ta! Comunitatea în școli și spitale</strong>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Vrem să transformăm și sistemul de sănătate și sistemul de educație, dintr-unul centrat pe poli- truci, într-unul centrat pe cetățeni.</p>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Politicienii au interese diferite față de cei care beneficiază de aceste servicii. Lăsând la o parte tentația deloc neglijabilă a corupției, politicienii nu cunosc la fel de bine nevoile comunității și specificitățile ei, precum le cunoaște comunitatea însăși.</p>
      <ul >
        <li className='mb-1 text-l p-2 indent-3 text-justify'>&#x2022; Sistemul de educație trebuie centrat pe elev, prin intermediul părinților și Părinții trebuie să poată alege școala copiilor lor dintr-o multitudine de opțiuni. Școlile tre- buie gestionate cât mai aproape de cetățeni, prin consililul lor de administrație, nu de po- litruci îndepărtați care vin în inspecții regizate. Pentru mediul rural și zonele defavorizate, vom demara programe moderne de atragere de profesori calificați, în cooperare cu comu- nitatea locală (condiții de locuire, sporuri speciale, recompensarea profesorilor în funcție de câți copii reușesc să continue școala la liceu / facultate). Finanțarea per elev pentru ca- zurile sociale va fi mai mare decât cea standard (programul detaliat al USR pentru educație cuprinde deja aceste măsuri: <a className='font-bold text-blue-600' href="https://www.usr.ro/files/PG-Educatie_.pdf">https: /www.usr.ro/files/PG-Educatie_.pdf</a>)</li>
        <li className='mb-1 text-l p-2 indent-3 text-justify'>&#x2022; Sistemul de sănătate trebuie centrat pe pacient și pe asigurat (potențialul pacient). În pre- zent, sistemul este centrat pe Astfel nu e de mirare că sistemul de sănătate este cel mai mare bolnav din țara noastră. În fiecare zi auzim noi povești și suntem șocați de cât de rău se poate ajunge. S-a ajuns la punctul la care ONG-uri private cu donații 100% private construiesc spitale de la zero pentru stat. Și chiar și așa, sunt șicanate constant de diverși oameni puternici ai sistemului care au impresia că dacă permit concurența și inovația, nu va mai fi loc de ei. De aceea vom înlesni investițiile în sănătate și înființarea de noi clinici și spitale. Dar mai ales, vom da dreptul fiecărui român de a-și alege propriul asigurator de sănătate. Fiecare român va avea o sumă fixă pe care o va putea folosi pentru a-și alege asiguratorul de sănătate, fie că este CNAS fie că este altul. Doar atunci când vom permite concurența la CNAS, vom avea servicii mai bune de sănătate.</li>
      </ul>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Sistemele acestea pot merge bine dacă îi au în centru pe beneficiari. Românii vor putea avea servicii de educație și sănătate de calitate atunci când sistemele de educație și de sănătate le vor permite să aleagă ce e mai bine pentru copiii lor și pentru ei înșiși. Adică, sisteme fără pile și relații, și fără corupție și șpagă. Sisteme care nu îi fac în mod constant pe români să se simtă neputincioși pentru că decizia se ia în altă parte.</p>
      </li>
      <li className='mt-4'><strong>6. Politici de mediu responsabile și realiste</strong>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Un mediu curat devine o cerință critică pentru generația noastră și pentru copiii noștri, de aceea trebuie să devină un principiu de bază al politicilor publice fără de care adevărata modernizare nu poate avea loc. Promovăm politici concrete, reale pentru modernizarea sistemelor de reci- clare a deșeurilor, pentru protecția pădurilor, pentru apă și aer curat. <strong>Mecanisme de piață și o intervenție inteligentă a statului</strong> (de exemplu, prin integrarea principiilor economiei circulare) sunt soluțiile pentru a asigura un mediu sănătos. Încurajarea transportului public, introducerea stimulentelor financiare pozitive și negative pentru a decongestiona marile orașe. Finanțarea intervențiilor pentru mediu prin mecanisme comunitare implicate direct în protejarea ariilor na- turale, nu prin crearea de noi birocrații de stat. Funcția de reglementare și control a statului în probleme de mediu trebuie întărită semnificativ. Deși e membră UE, România se îndepărtează de standardele europene de mediu, a cheltuit prost fondurile europene pentru mediu. Administrația de mediu trebuie reformată urgent, trebuie închise portițele pentru derogări, urbanism neglijent și soluții de mântuială. Primarii și consilierii locali ai USR aleși la viitoarele alegeri locale vor fi lideri ai comunităților lor în promovarea unui mediu de viață curat și a legalității în domeniu.</p>
      </li>
      <li className='mt-4'><strong>7. O reformă radicală a statului și o debirocratizare serioasă. Planul pentru digitalizarea administrației.</strong>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Birocrația de stat a devenit enormă, vom susține un guvern cu <strong>număr redus de ministere</strong>, care va face o analiză drastică a agențiilor guvernamentale și desființarea celor redundante. Fiecare program guvernamental va fi evaluat ca impact o dată la 3 ani și cele care nu obțin rezultate sa- tisfăcătoare nu vor mai fi finanțate de la bugetul de stat. <strong> Cancelaria Guvernului</strong> va avea această funcție de bază. <strong> Digitalizarea relației stat – cetățean</strong>: interzicerea în termen de doi ani a practicii de a cere de la cetățean informații / hârtii care sunt deținute de altă instituție a statului. Această cerință a mai fost emisă, dar a rămas literă moartă în condițiile în care bazele de date publice nu sunt interconectate. Noi nu promitem ceva vag, noi punem pe masă un plan: în primul an, Cance- laria Guvernului va porni procesul de legare a bazelor de date, obligatoriu pentru toate instituțiile. Niciun leu din fonduri europene sau naționale nu se va plăti pentru softuri utilizate de instituțiile publice care nu sunt integrate (nu permit accesul securizat al tuturor celorlalte instituții la ele). În anul al doilea se vor pune la punct platformele – interfață pentru cetățeni, interconectate și acestea cu bazele de date. Harta procesului va fi publicată și accesibilă tuturor cetățenilor în timp real. Vom cere Parlamentului României și vom vota onorarea promisiunii făcute cetățenilor: maxim 300 de parlamentari. Vom demara procesul de modificare a Constituției pentru a repara sistemul politic, începând cu <strong className='text-[#FF0021] '>#FărăPenaliÎnFuncțiiPublice</strong>.</p>
      </li>
      <li className='mt-4'><strong>8. Grijă pentru banii publici. Regiuni și comune funcționale. Reforma sistemului de management al fondurilor europene și a politicii de dezvoltare în general</strong>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Unificarea fondurilor europene și naționale de dezvoltare într-un buget unic, cu posibilitatea de finanțare a obiectivelor de investiții din bugetul național, pentru un flux continuu al investițiilor. Banii publici pentru dezvoltare (europeni sau naționali) trebuie dați pe o programare clară, pe bază de nevoi, multianual (programul USR pentru dezvoltare și fonduri europene: <a className='font-bold text-blue-600' href="https://www.usr.ro/wp-content/uploads/2019/03/PG-Fonduri-europene-si-dezvoltare.pdf"> https: /www. usr.ro/wp-content/uploads/2019/03/PG-Fonduri-europene-si-dezvoltare.pdf</a>). Regiunile de dezvoltare trebuie să capete sens real și să preia din puterea județelor. Vom porni o analiză serioasă a viabilității comunelor, din care cea mai mare parte nu își pot asigura acum nici salariile propriilor angajați din bugetele curente.

Comasarea funcțională a comunelor va avea la bază această analiză – prin unificarea anumitor servicii în centre comunale mai mari (de exemplu: juridic, atragere fonduri europene), păstrând servicii de bază de care au nevoie direct cetățenii în comunele actuale. Vom demara reforma radicală a sistemului administrativ de gestionare a fondurilor europene. Finanțarea de proiec- te prin bănci, consorții de ONG-uri, organisme intermediare private sau comunitare ca soluție preferată și mai puțin prin birocrația de stat care a devenit endemică și în continuă creștere. Folosirea masivă a instrumentelor financiare în exercițiul bugetar viitor 2020 – 2027. O dezbatere publică reală privind bugetul european 2020 – 2027 (n+3), realizată de la nivel politic înalt. Poate fi ultima șansă de dezvoltare cu fonduri europene.</p>
      </li>
      <li className='mt-4'><strong>9. Companiile de stat vor fi listate la bursă sau dedicate unui bine public, prin profesionalizare</strong>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Vom demara o analiza profesionistă a companiilor de stat, de la nivel central și local. Acestea au devenit o plagă prin jaf și politizare. Niciun partid vechi nu a avut curaj să spargă cercul vicios al imperiului sinecurilor reprezentate de angajări sau participarea la consiliile de administrație ale acestor companii de stat, multe dintre ele falimentare.</p>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>Listarea la bursă a celor care nu au un mandat clar și un bine public pe care îl urmăresc și pe care o structură privată nu îl poate îndeplini. Pentru cele rămase în portofoliu, statul intervine cu un mandat oferit transparent, pe baza căruia selectează un management profesionist.</p>
      </li>
      <li className='mt-4'><strong>10. Rezolvăm restanțele tranziției la democrație: Drepturi și libertăți civile pro- tejate.Încurajarea mediei independente, politici culturale competitive, controlul real democratic al serviciilor de informații</strong>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>O societate prosperă nu poate exista fără libertatea oamenilor de a-și căuta fericirea. Susținem parteneriatul civil pentru toate cuplurile, indiferent de orientarea sexuală. Susținem toleranța ca virtute modernizatoare a societății și respingem orice formă de xenofobie, homofobie, rasism, antisemitism sau șovinism. Într-o eră digitală în care tehnologia devine tot mai importantă pen- tru noi toți, într-un context de securitate foarte delicat, susținem că nu trebuie să cedăm de la respectarea unui drept esențial, acela la inviolabilitatea vieții private.</p>
      <p className='mb-1 text-l p-2 indent-3 text-justify'>O presă pluralistă și un public educat în spiritul democrației sunt vitale pentru o dezvoltare de- mocratică, bazată pe comunități. Statul trebuie să creeze cadrul pentru pluralism, transparență și concurență corectă în mass-media . Reforma TVR și a radioului public, accesul producătorilor independenți în serviciile publice de media vor fi parte din mandatul nostru. Cultura indepen- dentă, producătorii independenți trebuie să aibă acces la fondurile publice destinate culturii, pe bază de proiecte concrete și competitive. Vom iniția o reformă reală a serviciilor de informații și limitarea prin lege a capacității lor de a deține firme, de a interfera în viața politică, mediatică și economică. Supravegherea parlamentară a serviciilor de informații trebuie să devină o practică reală, nu o formă fără fond cum e acum.</p>
      <p className='mb-1 text-l p-2 indent-3 text-justify font-extrabold underline'>USR își asumă ca generație un destin neo-pașoptist. De aceea vom finaliza ce au început pașoptiștii atunci când au declanșat prima modernizare a României: suntem români mândri de tradițiile noastre, într-o Europă care cultivă specificul fiecăruia. Românii din Diaspora con- stituie din nou un element esențial al modernizării, le facilităm implicarea și le recunoaștem contribuția. România va fi mai puternică și mai respectată dacă își asumă acest parcurs de re- formă european. Patriotismul nostru este patriotismul faptelor, al grijii și al implicării.</p>
      </li>
     </ol>
      
    </div>
  );
};

export default RomaniaModerna;
