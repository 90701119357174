import React from "react";
import Bodea from '../assets/CandidatPrimarie.jpg'
import {Helmet} from 'react-helmet'

function ComunicatPresaNoiembrie() {
  return (
    <div className="max-w-3xl mx-auto flex flex-col items-center text-[#002A59] justify-center pt-20 bg-white bg-opacity-90">
      <Helmet>
        <title>Candidat Primarie Iași | USR Iași</title>
        <meta name="keywords" content="Marius Bodea, Primar Iași, alegeri 2024, alegeri locale, USR, Uniunea Salvati Romania, USR IASI"/>
        <meta name="description" content="USR Iași anunță desemnarea oficială a candidatului pentru Primăria Municipiului Iași" />
      </Helmet>
      <h1 className="text-3xl flex justify-center text-center font-bold mt-4 mb-8">Candidatul USR pentru postul de Primar al Municipiului Iași</h1>
      
      <img src={Bodea} alt="Caravana USR Dizabilități La Iași" className="w-[20rem] mb-8" />
      <div className="prose prose-lg font-eleron text-xl">
        <p className="text-justify p-2 m-2 indent-5"><strong>USR Iași</strong> anunță desemnarea oficială a <strong>candidatului pentru Primăria Municipiului Iași</strong>, în persoana senatorului <strong> Marius Bodea</strong>, în urma îndeplinirii tuturor procedurilor, conform calendarului intern. Este un pas semnificativ în vederea pregătirii <strong>alegerilor locale din anul 2024</strong>, care pentru Iași trebuie să însemne o <strong className="text-[#FF0021]">schimbare radicală în administrație și un nou început</strong>, bazat pe competență și care să aibă în centrul său <span className="underline">dezvoltarea sustenabilă a orașului</span>, în acord cu importanța sa de centru regional. </p>
        <p className="text-justify p-2 m-2 indent-5">Suntem de prea mult timp pe buza prăpastiei, cu o Primărie aflată în pragul falimentului, incapabilă să își onoreze facturi de zeci de milioane de euro, paralizată de dosarele penale ale actualului primar, cu ramificații adânci în întreaga structură de conducere. Nici un proiect major de infrastructură nu a fost demarat la Iași în acești ultimi trei ani, în condițiile în care atât guvernarea locală, cât și cea națională au fost asigurate de aceeași culoare politică. Nici una dintre marile promisiuni din anul 2020 nu au fost onorate, totul rămânând la stadiul de planșe și machete. </p>
        <p className="text-justify p-2 m-2 indent-5">„Odată cu desemnarea mea oficială în calitatea de candidat al USR Iași pentru Primărie, pornim pe un drum ferm care va aduce, în sfârșit, schimbarea regimului local falimentar, măcinat de corupție, condus de facto de mafia imobiliară. Această schimbare nu este despre noi, cei din USR, ci este despre fiecare ieșean care își dorește un oraș cu un trafic decent, cu un aer mai puțin poluat, cu infrastructură școlară de nivelul lumii civilizate, cu un transport public performant capabil să devină principale alegere în privința mobilității și gratuit. </p>
        <p className="text-justify p-2 m-2 indent-5">Toată campania internă din ultimii ani și tot efortul privind proiectele și soluțiile prezentate în spațiul public nu le-am făcut singur. Nici un demers complex nu poate fi făcut pe persoană fizică, iar succesul unuia precum câștigarea Primăriei Municipiului Iași nu poate fi garantat decât de o echipă solidă. Am lucrat împreună cu colegii mei din USR Iași și cu profesioniști din afara partidului pentru a demonstra în mod direct că există soluții pentru principalele probleme ale Iașului: unele chiar la îndemână, în vreme ce pentru implementarea altora sunt necesare mai mult curaj și mai multă pricepere. Chiar și oferite la cheie administrației Iașului, nu doar că nu au fost implementate, ci au fost ignorate ori preluate pe mici bucăți, fără a garanta astfel impactul dorit final. Nimic din ceea ce am elaborat în materie de proiecte mari pentru comunitate nu am ascuns pentru campanie, ci pe toate le-am adus în atenția publicului în orice formă posibilă, de la conferințe de presă, la televiziune, social media și până la broșuri distribuite în peste o jumătate de milion de exemplare. Am lucrat, așadar, pentru comunitate neîncetat cu mult înainte de a se da startul vreunei curse electorale. </p>
        <p className="text-justify p-2 m-2 indent-5">Desigur, drumul care va fi încununat de câștigarea Primăriei Municipiului Iași și, din nou, a celui mai mare număr de consilieri locali, are nevoie de toți oamenii valoroși din Iași care realizează fundătura în care am ajuns astăzi cu administrația penelistă. Deschidem larg porțile USR Iași către toți profesioniștii care vor să ni se alăture, către toți oamenii valoroși care doresc să vină alături de noi în Consiliul Local. Căutăm ieșeni de pe fiecare stradă, din fiecare bloc al orașului care să facă parte în mod direct din echipa care va aduce schimbarea în bine la Iași!</p>
        <p className="text-justify p-2 m-2 indent-5">O competiție pentru Primăria Municipiului Iași, asumată serios, așa cum mi-am asumat serios orice misiune în viața mea, nu este doar despre campania electorală, ci și despre capacitatea de a face față încă din prima zi mandatului. </p>
        <p className="text-justify p-2 m-2 indent-5">Am fost aici în toți acești ani, cunosc cel mai bine ce au nevoie oamenii din acest oraș, am venit cu soluții pentru toate marile probleme și le pot pune în aplicare din prima secundă de mandat. </p>
        <p className="text-justify p-2 m-2 indent-5">Candidatura mea este asumată și fermă. Iașul modern și civilizat este proiectul vieții mele. Este ceea ce mă definește, este ceea ce mă ține în politică!”, a declarat senatorul Marius Bodea, candidatul USR pentru funcția de Primar al Municipiului Iași.</p>
        <p className="text-justify p-2 m-2 indent-5">„Iașul are nevoie de un edil cinstit care să redea oamenilor speranța că în cea mai importantă instituție pentru ei, vor găsi o echipă disponibilă, gata să îi asculte și să îi ajute. Cred cu tărie că Marius Bodea este singura opțiune ca primar pentru un oraș care parcă a fost „retrocedat” mafiei și care a rămas în urmă, pentru că în loc să primească cetățenii ceea ce merită, în comunitatea lor, au primit hoții, pe tavă. USR vine cu un om integru, determinat, muncitor, care știe și ce are de făcut, dar și ce nu s-a făcut. Pentru că a fost mereu aici. Am încredere că ieșenii care sunt oameni foarte înțelepți, nu vor mai accepta să fie păcăliți și dezavantajați și nu vor face cadou următorii patru ani unei administrații care se întâlnește mai des cu procurorii, decât cu cetățenii. Propunem deci o schimbare fără amânare, absolut necesară”, a declarat Filip Havârneanu, președintele filialei municipale a USR Iași.</p>
        
      </div>
    </div>
  );
}

export default ComunicatPresaNoiembrie;
